<template>
  <b-container fluid>
    <h4>Banner</h4>
    <b-row>
      <b-col>
        <b-form @submit="submitForm">
          <form-input
            v-model="form.description"
            label="Descrição:"
            placeholder="Digite a descrição da caixa"
            icon="card-text"
            required
            :errors="errors['description']"
          />

          <b-row>
            <b-col>
              <form-file
                v-model="form.site"
                :link-to-open="form.link"
                :placeholder="form.link || `Banner para ser exibido no site`"
                label="Banner do site:"
                icon="card-image"
              />
            </b-col>
            <b-col>
              <form-file
                v-model="form.app"
                :link-to-open="form.linkMobile"
                label="Banner do app"
                :placeholder="
                  form.linkMobile || `Banner para ser exibido no app`
                "
                icon="file-image"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <form-select
                v-model="form.linkPrefix"
                label="Escolha o prefixo do link:"
                :options="linkOptionsSelect"
                icon="link45deg"
                :errors="errors['linkPrefix']"
              ></form-select>
            </b-col>
            <b-col>
              <form-input
                v-model="form.url"
                label="Link Promocional"
                placeholder="Digite a URL destino do banner"
                icon="link45deg"
                :errors="errors['url']"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <form-input
                :value="
                  form.linkPrefix
                    ? `${form.linkPrefix.toLowerCase()}/${form.url}`
                    : `${form.url}`
                "
                disabled
                copyable
                icon="files"
              ></form-input>
            </b-col>
          </b-row>

          <b-form-group
            label="Opções de exibição:"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio
              v-model="form.selected"
              :aria-describedby="ariaDescribedby"
              value="secondary"
            >
              Secundario
            </b-form-radio>
            <b-form-radio
              v-model="form.selected"
              :aria-describedby="ariaDescribedby"
              value="inferior"
            >
              Inferior
            </b-form-radio>
          </b-form-group>

          <b-button type="submit" variant="primary">SALVAR</b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import FormFile from "../../components/form/FormFile.vue";
import FormInput from "../../components/form/FormInput.vue";
import FormSelect from "../../components/form/FormSelect.vue";

export default {
  components: { FormInput, FormFile, FormSelect },
  name: "detailsBanner",
  data() {
    return {
      form: { ...this.$store.state.banner.banner },
      linkOptionsSelect: [
        { id: null, text: "SEM PREFIXO" },
        { id: "EXTRA", text: "extra/" },
        // { id: "PROMOTION", text: "/promocao/" },
      ],
    };
  },
  computed: {
    ...mapState("banner", ["errors"]),
  },
  methods: {
    ...mapActions("banner", ["createBanner", "updateBanner", "getOneBanner"]),
    ...mapMutations(["setToasted", "setOverlay"]),

    async submitForm(event) {
      event.preventDefault();
      const { form } = this;

      if (form.id) {
        this.updateBanner(form);
      } else {
        this.createBanner(form);
      }
    },
    async initialFetch() {
      const { id } = this.$route.params;
      if (id && !this.form.id) {
        await this.getOneBanner(id);
        this.form = { ...this.$store.state.banner.banner };
      }
      console.log(id, this.form);
    },
  },
  async created() {
    this.setOverlay(true);
    await this.initialFetch();
    this.setOverlay(false);
  },
};
</script>

<style></style>
