<template>
  <b-form-group>
    <template v-if="label" v-slot:label>
      <label>
        {{ label }}

        <span v-if="required" class="text-danger"> *</span>
      </label>
    </template>

    <b-input-group>
      <b-input-group-prepend
        v-if="icon"
        is-text
        :class="{ 'cursor-pointer': linkToOpen }"
        :title="linkToOpen && 'Abrir arquivo'"
        @click="openFile"
      >
        <b-icon :icon="icon" />
      </b-input-group-prepend>

      <b-form-file
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        :accept="accept"
        @input="$emit('input', $event)"
      />
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: "FormInput",
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    accept: {
      type: String,
      require: true,
    },
    linkToOpen: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    openFile() {
      if (this.linkToOpen) {
        window.open(this.linkToOpen, "_blank");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
